*{
  box-sizing: border-box;
}
body{
  background: url(./assets/img/bg.png);
  background-position: center cebter;
  background-size: cover;
}

.section{
  margin-top: 10%;
  display: inline-block;
  width: 50%;
  text-align: center;
  height: 300px;
  vertical-align: middle;

}
.app .hero{
  position: relative;
}

.hero button{
  position: absolute;
  top: 50%;
  /* left: 0; */
  background: rgba(0,0,0,0.5);
  color: #fff;
  padding: 10px 30px ;
}
.wraper{
  width: 90%;

}
.hero .next{
  right: 0;
}
.hero .prev{
  left: 0;
}
.d-flex{
  /* justify-content: center; */
  align-items: center;
  justify-content: center;
  display:flex;
  height: 300px;
}
.form input{
  padding: 10px;
  background: rgba(255,255,255,.5);
  border: 1px solid #fff;
  text-align: right;
  font-size: 20px;
}
.form button{
  padding: 10px;
  background: rgba(0,0,0,0.5);
  color: #fff;
}

.form button,.form input {
  display: block;
  width: 100%;
}

.status{
  position: absolute;
  top: 0;
  right: 0;
}
.online-users{
  position: absolute;
  top: 0;
  left: 0;
}

.status,.online-users{
  display: inline-block;
  padding: 10px;
  background: rgba(0,0,0,0.5);
  color: #fff;
}
.chat{
  background: rgba(0,0,0,0.5);
  position:absolute;
  bottom: 0;
  right: 0;
  color: #fff;
  padding: 10px;
}
.chat .messages{
  height: 150px;
  text-align: right;
  direction:rtl;
  width: 400px;
  overflow-y: auto;
  margin-bottom: 10px;
}

.chat input[type=text]{
  padding: 7px;
  background: rgba(255,255,255,0.4);
  border: 1px solid #fff;
  width: 82.5%;
}

.chat input[type=submit]{
  background: #fff;
  border: 1px solid #fff;
  padding: 7px 20px;
}


/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #555;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #fff;
}

.user{
  position: absolute;
    top: 100px;
  left: 100px;
  
}

.game{
  height: 90vh;
}
.game:focus-visible {
    outline: 0;
}
.user .name{
    position: absolute;
    bottom: 111%;
    width: 164px;
    padding: 5px;
    font-size: 13px;
    color: #fff;
    background: rgba(0,0,0,0.2);
    text-align: center;
    left: -50%;

}
.user .message{
    position: absolute;
    left: 119%;
    background: rgba(255,255,255,.7);
    padding: 7px 15px;
    border-radius: 7px;
    top: 51px;
    width: 150px;
}

.user .message::after{
  position: absolute;
  right: 100%;
  top: 10px;
  content: '';
  border: 7px solid;
  border-color: transparent rgba(255,255,255,.3) transparent transparent ;
}
.user::after{
  content: '';
  position: absolute;
  height: 50px;
  width: 50px;
  background: rgba(0,0,0,0.3);
  border-radius: 50%;
  top: 100%;
  left: 50%;
  transform: rotateX(79deg) translateX(-50%);
  
}